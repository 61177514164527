import { PagedResponse } from '@/types/PagedResponse'

import { ApiClient } from '../client'

import { User } from './types/User'
import { UserAdd } from './types/UserAdd'
import { UserSearchFilter } from './types/UserSearchFilter'
import { UserSearchRow } from './types/UserSearchRow'

const baseURL = 'v1.0/users'

export const addUser = async (email: string) => {
  const client = new ApiClient()

  const body: UserAdd = {
    email,
  }

  const { data } = await client.request<User>({
    method: 'post',
    url: `${baseURL}`,
    data: body,
  })

  return data
}

export const searchUsers = async (filter?: UserSearchFilter) => {
  const client = new ApiClient()

  const { data } = await client.request<PagedResponse<UserSearchRow>>({
    method: 'get',
    url: `${baseURL}/search`,
    params: filter,
  })

  return data
}

export const getUser = async (uuid?: string) => {
  const client = new ApiClient()

  const { data } = await client.request<User>({
    method: 'get',
    url: `${baseURL}/${uuid}`,
  })

  return data
}

export const getUserRoles = async (uuid?: string) => {
  const client = new ApiClient()

  const { data } = await client.request<User>({
    method: 'get',
    url: `${baseURL}/${uuid}/roles`,
  })

  return data
}

export const addUserRole = async (userUuid?: string, roleUuid?: string) => {
  const client = new ApiClient()

  const { data } = await client.request<boolean>({
    method: 'post',
    url: `${baseURL}/${userUuid}/roles`,
    data: {
      roleId: roleUuid,
    },
  })

  return data
}

export const removeUserRole = async (userUuid?: string, roleUuid?: string) => {
  const client = new ApiClient()

  const { data } = await client.request<boolean>({
    method: 'delete',
    url: `${baseURL}/${userUuid}/roles/${roleUuid}`,
  })

  return data
}

export const updateUser = async (user?: User) => {
  const client = new ApiClient()

  const { data } = await client.request<boolean>({
    method: 'put',
    url: `${baseURL}/${user?.uuid}`,
    data: user,
  })

  return data
}
