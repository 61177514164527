import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import { addSecret, getAllDestinations } from '@/api/secrets'
import { SecretCreateBody } from '@/api/secrets/types/SecretCreateBody'

interface SelectRow {
  key: string
  value: string
}

export const SecretAddPage: React.FC = () => {
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [secret, setSecret] = useState('')
  const [destinationUUID, setDestination] = useState('')
  const [showError, setShowError] = useState(false)
  const [options, setOptions] = useState<SelectRow[]>([])

  useEffect(() => {
    getAllDestinations().then(data => {
      const results: SelectRow[] = []
      data.results.forEach(value => {
        results.push({
          key: value.name,
          value: value.uuid,
        })
      })
      setOptions([{ key: 'Select an destination', value: '' }, ...results])
    })
  }, [])

  const navigate = useNavigate()
  const routeChange = () => {
    const path = `/secrets`
    navigate(path)
  }

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false)
    setName(event.target.value)
  }

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false)
    setType(event.target.value)
  }

  const onChangeMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false)
    setSecret(event.target.value)
  }

  const onChangeDestination = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setShowError(false)
    setDestination(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const body: SecretCreateBody = {
      name: name,
      type: type,
      secret: secret,
      destinationUUID: destinationUUID,
    }

    addSecret(body)
      .then(routeChange)
      .catch(() => {
        setShowError(true)
      })
  }

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/secrets')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <h2 className="mt-[2%]">Add New Secret</h2>
            <form className="max-w-[75%] flex flex-col flex-wrap gap-[15px] mt-[2%]" onSubmit={handleSubmit}>
              <div className="max-w-[375px] flex flex-col flex-wrap gap-[15px]">
                <label className="flex justify-between w-full">
                  Name:
                  <input className="w-[300px]" type="text" value={name} onChange={onChangeName} />
                </label>
                <label className="flex justify-between w-full">
                  Type:
                  <input className="w-[300px]" type="text" value={type} onChange={onChangeUrl} />
                </label>
                <label className="flex justify-between w-full">
                  Secret:
                  <input className="w-[300px]" type="text" value={secret} onChange={onChangeMethod} />
                </label>
                <label className="flex justify-between w-full">
                  Environment:
                  <select onChange={onChangeDestination} value={destinationUUID}>
                    {options.map(option => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      )
                    })}
                  </select>
                </label>
              </div>
              <div>
                <button
                  className={classNames(
                    'px-3 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-500 focus:bg-indigo-500',
                  )}
                  type="submit"
                  value="Submit"
                >
                  Submit
                </button>
                <button
                  onClick={handleCancel}
                  className="px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500"
                >
                  Cancel
                </button>
              </div>
              {showError ? <p>There was a problem adding the secret!</p> : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
