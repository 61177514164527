import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { FourOhFourPage } from './pages/404'
import { DashboardPage } from './pages/dashboard'
import { DestinationPage } from './pages/destinations'
import { DestinationAddPage } from './pages/destinations/add-destination'
import { DestinationEditPage } from './pages/destinations/edit-destination'
import { DestinationViewPage } from './pages/destinations/view-destination'
import { EnvironmentPage } from './pages/environments'
import { EnvironmentAddPage } from './pages/environments/add-environment'
import { EnvironmentEditPage } from './pages/environments/edit-environment'
import { EnvironmentViewPage } from './pages/environments/view-environment'
import { OrganizationPage } from './pages/organizations'
import { OrganizationAddPage } from './pages/organizations/add-organization'
import { OrganizationEditPage } from './pages/organizations/edit-organization'
import { OrganizationViewPage } from './pages/organizations/view-organization'
import { SecretPage } from './pages/secrets'
import { SecretAddPage } from './pages/secrets/add-secret'
import { SecretEditPage } from './pages/secrets/edit-secret'
import { SecretViewPage } from './pages/secrets/view-secret'
import { TenantsPage } from './pages/tenants'
import { TenantsAddPage } from './pages/tenants/add-tenant'
import { TenantsEditPage } from './pages/tenants/edit-tenant'
import { TenantsViewPage } from './pages/tenants/view-tenant'
import { UsersPage } from './pages/users'
import { AddUserPage } from './pages/users/add-user'
import { UsersEdit } from './pages/users/edit-user'

/**
 * React Router 6
 * @see https://reactrouter.com/docs/en/v6/getting-started/overview
 */
export const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/dashboard" />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/environments" element={<EnvironmentPage />} />
      <Route path="/environments/:uuid" element={<EnvironmentEditPage />} />
      <Route path="/environments/view/:uuid" element={<EnvironmentViewPage />} />
      <Route path="/environments/add" element={<EnvironmentAddPage />} />
      <Route path="/destinations" element={<DestinationPage />} />
      <Route path="/destinations/:uuid" element={<DestinationEditPage />} />
      <Route path="/destinations/view/:uuid" element={<DestinationViewPage />} />
      <Route path="/destinations/add" element={<DestinationAddPage />} />
      <Route path="/organizations" element={<OrganizationPage />} />
      <Route path="/organizations/:uuid" element={<OrganizationEditPage />} />
      <Route path="/organizations/view/:uuid" element={<OrganizationViewPage />} />
      <Route path="/organizations/add" element={<OrganizationAddPage />} />
      <Route path="/secrets" element={<SecretPage />} />
      <Route path="/secrets/:uuid" element={<SecretEditPage />} />
      <Route path="/secrets/view/:uuid" element={<SecretViewPage />} />
      <Route path="/secrets/add" element={<SecretAddPage />} />
      <Route path="/tenants" element={<TenantsPage />} />
      <Route path="/tenants/:uuid" element={<TenantsEditPage />} />
      <Route path="/tenants/view/:uuid" element={<TenantsViewPage />} />
      <Route path="/tenants/add" element={<TenantsAddPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/:uuid" element={<UsersEdit />} />
      <Route path="/users/add" element={<AddUserPage />} />
      <Route path="/destinations" element={<AddUserPage />} />
      <Route path="*" element={<FourOhFourPage />} />
    </Routes>
  )
}
