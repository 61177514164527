import React, { useEffect, useState } from 'react'

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'

import { searchOrganizations } from '@/api/organizations'
import { OrganizationSearchRow } from '@/api/organizations/types/OrganizationSearchRow'

type OrganizationSearchResponse = Awaited<ReturnType<typeof searchOrganizations>>

const columnHelper = createColumnHelper<OrganizationSearchRow>()

const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('site', {
    header: 'Site',
  }),
]

export const DashboardPage: React.FC = () => {
  const [organizations, setOrganizations] = useState<OrganizationSearchResponse>()

  useEffect(() => {
    searchOrganizations().then(setOrganizations)
  }, [])

  const data = (organizations && organizations?.results) ?? []

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, i) => (
                        <th
                          key={header.id}
                          scope="col"
                          className={classNames(
                            'py-3.5 text-left text-sm font-semibold text-gray-900',
                            i === 0 ? 'pl-4 pr-3 sm:pl-6' : 'px-3',
                          )}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody className="bg-white">
                  {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className={row.index % 2 === 0 ? undefined : 'bg-gray-50'}>
                      {row.getVisibleCells().map((cell, i) => (
                        <td
                          key={cell.id}
                          className={classNames(
                            'whitespace-nowrap  py-4 text-sm text-gray-500',
                            i === 0 ? 'pl-4 sm:pl-6 font-medium' : 'px-3',
                          )}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          href={`/organizations/${row.original.uuid}`}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Edit
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
