import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'

import { deleteSecret, searchSecrets } from '@/api/secrets'
import { SecretSearchRow } from '@/api/secrets/types/SecretSearchRow'

type SecretSearchResponse = Awaited<ReturnType<typeof searchSecrets>>

const columnHelper = createColumnHelper<SecretSearchRow>()

const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('type', {
    header: 'Url',
  }),
]

export const SecretPage: React.FC = () => {
  const [secrets, setSecrets] = useState<SecretSearchResponse>()

  const navigate = useNavigate()
  const routeChange = () => {
    const path = `add`
    navigate(path)
  }

  useEffect(() => {
    searchSecrets().then(setSecrets)
  }, [])

  const onClickRow = (event: React.FormEvent<EventTarget>): void => {
    event.preventDefault()
    const target = event.target as HTMLInputElement
    navigate(`/secrets/view/${target.dataset.uuid}`)
  }

  const onClickDelete = async (event: React.FormEvent<EventTarget>): Promise<void> => {
    event.preventDefault()
    const target = event.target as HTMLInputElement
    const response = confirm(`Are you sure you want to delete: ${target.dataset.name}?`)
    if (response) {
      await deleteSecret(target.value)
      window.location.reload()
    }
  }

  const data = (secrets && secrets?.results) ?? []

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header, i) => (
                        <th
                          key={header.id}
                          scope="col"
                          className={classNames(
                            'py-3.5 text-left text-sm font-semibold text-gray-900',
                            i === 0 ? 'pl-4 pr-3 sm:pl-6' : 'px-3',
                          )}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  ))}
                </thead>
                <tbody className="bg-white">
                  {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className={row.index % 2 === 0 ? undefined : 'bg-gray-50'}>
                      {row.getVisibleCells().map((cell, i) => (
                        <td
                          key={cell.id}
                          className={classNames(
                            'whitespace-nowrap  py-4 text-sm text-gray-500',
                            i === 0 ? 'pl-4 sm:pl-6 font-medium' : 'px-3',
                          )}
                        >
                          <button onClick={onClickRow} data-uuid={row.original.uuid}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </button>
                        </td>
                      ))}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 mx-5 text-right text-sm font-medium sm:pr-6">
                        <a href={`/secrets/${row.original.uuid}`} className="text-indigo-600 hover:text-indigo-900">
                          Edit
                        </a>
                        <button
                          className="text-rose-600 hover:text-rose-900 mx-5"
                          onClick={onClickDelete}
                          data-name={row.original.name}
                          value={row.original.uuid}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={'mt-4'}>
              <button
                className="inline-flex px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm  hover:bg-indigo-500 focus:bg-indigo-500"
                onClick={routeChange}
              >
                Add Secret
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
