import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import { JsonEditor } from '@/components/JsonEditor'

import { addEnvironment, getAllOrganizations } from '@/api/environments'
import { EnvironmentCreateBody } from '@/api/environments/types/EnvironmentCreateBody'

import { Nullable } from '@/types/Nullable'

interface SelectRow {
  key: string
  value: string
}

export const EnvironmentAddPage: React.FC = () => {
  const [name, setName] = useState('')
  const [site, setSite] = useState('')
  const [organizationUUID, setOrganization] = useState('')
  const [json, setJson] = useState<Nullable<object>>(null)
  const [jsonIsValid, setJsonIsValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const [rawJsonText, setRawJsonText] = useState<string>('{}')
  const [options, setOptions] = useState<SelectRow[]>([])

  useEffect(() => {
    getAllOrganizations().then(data => {
      const results: SelectRow[] = []
      data.results.forEach(value => {
        results.push({
          key: value.name,
          value: value.uuid,
        })
      })
      setOptions([{ key: 'Select an organization', value: '' }, ...results])
    })
  }, [])

  const navigate = useNavigate()
  const routeChange = () => {
    const path = `/environments`
    navigate(path)
  }

  useEffect(() => {
    setShowError(false)
  }, [rawJsonText])

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false)
    setName(event.target.value)
  }

  const onChangeSite = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false)
    setSite(event.target.value)
  }

  const onChangeOrganization = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setShowError(false)
    setOrganization(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!jsonIsValid) {
      return
    }

    const body: EnvironmentCreateBody = {
      name: name,
      site: site,
      organizationUUID: organizationUUID,
      config: json ?? {},
    }

    addEnvironment(body)
      .then(routeChange)
      .catch(() => {
        setShowError(true)
      })
  }

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/environments')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <h2 className="mt-[2%]">Add New Environment</h2>
            <form className="max-w-[75%] flex flex-col flex-wrap gap-[15px] mt-[2%]" onSubmit={handleSubmit}>
              <div className="max-w-[375px] flex flex-col flex-wrap gap-[15px]">
                <label className="flex justify-between w-full">
                  Name:
                  <input className="w-[300px]" type="text" value={name} onChange={onChangeName} />
                </label>
                <label className="flex justify-between w-full">
                  Site:
                  <input className="w-[300px]" type="text" value={site} onChange={onChangeSite} />
                </label>
                <label className="flex justify-between w-full">
                  Organization:
                  <select onChange={onChangeOrganization} value={organizationUUID}>
                    {options.map(option => {
                      return (
                        <option key={option.value} value={option.value}>
                          {option.key}
                        </option>
                      )
                    })}
                  </select>
                </label>
              </div>
              <JsonEditor value={json} setValue={setJson} setIsValid={setJsonIsValid} setRawValue={setRawJsonText} />
              <div>
                <button
                  className={classNames(
                    'px-3 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm',
                    !jsonIsValid ? 'bg-gray-600' : 'bg-indigo-600 hover:bg-indigo-500 focus:bg-indigo-500',
                  )}
                  type="submit"
                  value="Submit"
                  disabled={!jsonIsValid}
                >
                  Submit
                </button>
                <button
                  onClick={handleCancel}
                  className="px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500"
                >
                  Cancel
                </button>
              </div>
              {showError ? <p>There was a problem adding the environment!</p> : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
