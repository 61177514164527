import React, { useContext, useEffect, useMemo, useState } from 'react'

import { LoginManager } from '../../services/login/LoginManager'
import { UserStorage } from '../../services/login/UserStorage'
import { FCC } from '../../types/FCC'

import { AutoLogin } from './AutoLogin'
import { DefaultUserState, UserContext } from './UserContext'
import { UserContextState } from './UserContextState'
import { UserInfo } from './UserInfo'
import { UserAction, UserActions, UserActionType, UserDispatchContext, UserReducer } from './UserReducer'

const setCurrentUserFromStorage = (dispatch: React.Dispatch<UserActions>): Promise<void> => {
  const savedUser = UserStorage.getUser()

  if (savedUser) {
    dispatch({ type: UserActionType.login, payload: savedUser })
  } else {
    dispatch({ type: UserActionType.logout })
  }

  return Promise.resolve()
}

export const UserProvider: FCC = ({ children }) => {
  const [state, dispatch] = React.useReducer(UserReducer, DefaultUserState)

  const [didCheckForStorageForUser, setDidCheckForStorageForUser] = useState(false)

  useEffect(() => {
    setCurrentUserFromStorage(dispatch).finally(() => setDidCheckForStorageForUser(true))
  }, [])

  return didCheckForStorageForUser ? (
    <UserContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        <AutoLogin>{children}</AutoLogin>
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  ) : null
}

export const useUserAction = (): UserAction => {
  const dispatch = useContext(UserDispatchContext)
  if (dispatch === undefined) {
    throw new Error('UserDispatchContext is not available')
  }
  return useMemo(() => new UserAction(dispatch), [dispatch])
}

export const useUserState = (): Readonly<UserContextState> => {
  const state = useContext(UserContext)
  return state
}

export const useUser = (): Readonly<UserInfo> => {
  const state = useUserState()
  return state.user
}

export const useIsLoggedIn = (): boolean => {
  const state = useUserState()
  return state.isLoggedIn
}

export const useLoginManager = (): LoginManager => {
  const actions = useUserAction()
  return useMemo(() => new LoginManager(actions), [actions])
}
