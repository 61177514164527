import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import { JsonEditor } from '@/components/JsonEditor'

import { getDestination, updateDestination } from '@/api/destinations'
import { Destination } from '@/api/destinations/types/Destination'

import { Nullable } from '@/types/Nullable'

type DestinationParams = { uuid: string }

export const DestinationEditPage: React.FC = () => {
  const [destination, setDestination] = useState<Destination | undefined>()
  const [jsonIsValid, setJsonIsValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const [rawJsonText, setRawJsonText] = useState<string>('')

  const { uuid } = useParams<DestinationParams>()

  useEffect(() => {
    getDestination(uuid).then(setDestination)
  }, [uuid])

  const navigate = useNavigate()
  const routeChange = () => {
    const path = `/destinations`
    navigate(path)
  }

  useEffect(() => {
    setShowError(false)
  }, [rawJsonText])

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDestination({
      ...destination,
      name: event.target.value,
    } as Destination)
  }

  const onChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDestination({
      ...destination,
      url: event.target.value,
    } as Destination)
  }

  const onChangeMethod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDestination({
      ...destination,
      method: event.target.value,
    } as Destination)
  }

  const setValue = (obj: Nullable<object>) => {
    setDestination({
      ...destination,
      config: obj,
    } as Destination)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!jsonIsValid) {
      return
    }

    updateDestination(destination)
      .then(routeChange)
      .catch(() => {
        setShowError(true)
      })
  }

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/destinations')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <form onSubmit={handleSubmit}>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Destination Name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="name"
                    name="name"
                    id="name"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={destination?.name}
                    onChange={onChangeName}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Destination Url
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="url"
                    name="url"
                    id="url"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={destination?.url}
                    onChange={onChangeUrl}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Destination Method
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="method"
                    name="method"
                    id="method"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={destination?.method}
                    onChange={onChangeMethod}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Destination Config
                </label>
                {destination?.config && (
                  <JsonEditor
                    value={destination?.config}
                    setValue={setValue}
                    setIsValid={setJsonIsValid}
                    setRawValue={setRawJsonText}
                  />
                )}
              </div>
              <button
                type="submit"
                value="Submit"
                className={classNames(
                  'px-3 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm',
                  !jsonIsValid ? 'bg-gray-600' : 'bg-indigo-600 hover:bg-indigo-500 focus:bg-indigo-500',
                )}
              >
                Submit
              </button>
              <button
                type="submit"
                value="Cancel"
                onClick={handleCancel}
                className={classNames(
                  'inline-flex px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500',
                )}
              >
                Cancel
              </button>
              {showError ? <p>There was a problem adding the destination!</p> : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
