import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import { getSecret, updateSecret } from '@/api/secrets'
import { Secret } from '@/api/secrets/types/Secret'

type SecretParams = { uuid: string }

export const SecretEditPage: React.FC = () => {
  const [secret, setSecret] = useState<Secret | undefined>()
  const [showError, setShowError] = useState(false)

  const { uuid } = useParams<SecretParams>()

  useEffect(() => {
    getSecret(uuid).then(setSecret)
  }, [uuid])

  const navigate = useNavigate()
  const routeChange = () => {
    const path = `/secrets`
    navigate(path)
  }

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret({
      ...secret,
      name: event.target.value,
    } as Secret)
  }

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret({
      ...secret,
      type: event.target.value,
    } as Secret)
  }

  const onChangeSecret = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecret({
      ...secret,
      secret: event.target.value,
    } as Secret)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    updateSecret(secret)
      .then(routeChange)
      .catch(() => {
        setShowError(true)
      })
  }

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/secrets')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <form onSubmit={handleSubmit}>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Secret Name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="name"
                    name="name"
                    id="name"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={secret?.name}
                    onChange={onChangeName}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Secret Type
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="type"
                    name="type"
                    id="type"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={secret?.type}
                    onChange={onChangeType}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  The Secret
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="secret"
                    name="secret"
                    id="secret"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={secret?.secret}
                    onChange={onChangeSecret}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <button
                type="submit"
                value="Submit"
                className={classNames(
                  'px-3 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-500 focus:bg-indigo-500',
                )}
              >
                Submit
              </button>
              <button
                type="submit"
                value="Cancel"
                onClick={handleCancel}
                className={classNames(
                  'inline-flex px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500',
                )}
              >
                Cancel
              </button>
              {showError ? <p>There was a problem adding the secret!</p> : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
