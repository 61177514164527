import { createContext, Dispatch } from 'react'

import { ReducerError } from '../../utils/ReducerError'

import { anonymousUser } from './UserContext'
import { UserContextState } from './UserContextState'
import { UserInfo } from './UserInfo'

export enum UserActionType {
  setUser = 'setUser',
  login = 'login',
  logout = 'logout',
}

export type UserActions =
  | {
      type: UserActionType.setUser
      payload: UserInfo
    }
  | {
      type: UserActionType.login
      payload: UserInfo
    }
  | {
      type: UserActionType.logout
    }

export class UserAction {
  private _dispatch: Dispatch<UserActions>

  constructor(dispatch: Dispatch<UserActions>) {
    this._dispatch = dispatch
  }

  public login(user: UserInfo) {
    this._dispatch({ type: UserActionType.login, payload: user })
  }

  public logout() {
    this._dispatch({ type: UserActionType.logout })
  }

  public setUser(user: UserInfo) {
    this._dispatch({ type: UserActionType.setUser, payload: user })
  }
}

export const UserReducer = (state: UserContextState, action: UserActions) => {
  switch (action.type) {
    case UserActionType.setUser:
      return {
        ...state,
        user: action.payload,
      }
    case UserActionType.login:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
      }
    case UserActionType.logout:
      return {
        ...state,
        isLoggedIn: false,
        user: anonymousUser,
      }
    default:
      throw ReducerError('UserReducer', action)
  }
}

export const UserDispatchContext = createContext<Dispatch<UserActions> | undefined>(undefined)
