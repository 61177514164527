import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getEnvironment } from '@/api/environments'
import { Environment } from '@/api/environments/types/Environment'

type EnvironmentParams = { uuid: string }

export const EnvironmentViewPage: React.FC = () => {
  const [environment, setEnvironment] = useState<Environment | undefined>()
  const { uuid } = useParams<EnvironmentParams>()

  useEffect(() => {
    getEnvironment(uuid).then(setEnvironment)
  }, [uuid])

  const navigate = useNavigate()
  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/environments')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="relative mt-1 rounded-md shadow-sm">{environment?.name}</div>
            </div>
            <div className="my-4">
              <div className="relative mt-1">Site: {environment?.site}</div>
            </div>
            <div className="my-4">
              <div className="relative mt-1">
                Organization:{' '}
                <Link
                  to={`/organizations/view/${environment?.organizationUUID}`}
                  className={'text-indigo-600 hover:text-indigo-900'}
                >
                  {environment?.organizationName}
                </Link>
              </div>
            </div>
            <div className="my-4">
              <div className="relative mt-1">
                Tenant:{' '}
                <Link
                  to={`/tenants/view/${environment?.tenantUUID}`}
                  className={'text-indigo-600 hover:text-indigo-900'}
                >
                  {environment?.tenantName}
                </Link>
              </div>
            </div>
            <div className="my-4">
              <div className="relative mt-1">
                Config: <pre>{JSON.stringify(environment?.config) ?? ''}</pre>
              </div>
            </div>
            <form>
              <button
                onClick={handleCancel}
                className={
                  'inline-flex px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500'
                }
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
