import { ApiClient } from '../client'

import { AuthResult } from './types/AuthResult'
import { GoogleAuthInput } from './types/GoogleAuthInput'

export class TokenService {
  public static async authGoogle(input: GoogleAuthInput): Promise<AuthResult> {
    const client = new ApiClient()

    const { data, status } = await client.request<AuthResult | string>({
      method: 'POST',
      url: '/token/auth-google',
      data: input,
      validateStatus: code => {
        switch (code) {
          case 200:
          case 400:
          case 401:
            return true
          default:
            return false
        }
      },
    })

    switch (status) {
      case 200:
        return data as AuthResult
      default: {
        const message = data as string
        return Promise.reject(new Error(message))
      }
    }
  }
}
