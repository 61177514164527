import { GoogleLoginManager } from '@/components/GoogleLogin/GoogleLoginManager'

import { FCC } from '../../types/FCC'

import { useUserState } from './UserProvider'

export const AutoLogin: FCC = ({ children }) => {
  const { isLoggedIn } = useUserState()

  if (isLoggedIn) {
    return <>{children}</>
  }

  return <GoogleLoginManager />
}
