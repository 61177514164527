import React, { useEffect, useState } from 'react'

import classNames from 'classnames'

import { addUser } from '@/api/users'

export const AddUserPage: React.FC = () => {
  const [email, setEmail] = useState('')
  const [isEmailInvalid, setIsEmailInvalid] = useState(true)

  useEffect(() => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    setIsEmailInvalid(!regex.test(email ?? ''))
  }, [email, setIsEmailInvalid])

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (isEmailInvalid) {
      return
    }

    addUser(email)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="my-4">
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          New User Email
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            type="email"
            name="email"
            id="email"
            className={classNames(
              'block w-full p-2 rounded-md sm:text-sm',
              isEmailInvalid
                ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
            )}
            defaultValue={email}
            aria-invalid={isEmailInvalid ? true : false}
            aria-describedby={isEmailInvalid ? 'email-error' : ''}
            formNoValidate={true}
            onChange={onChangeEmail}
          />
        </div>
        {isEmailInvalid && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            Please enter a valid email address.
          </p>
        )}
      </div>
      <button
        type="submit"
        value="Submit"
        disabled={isEmailInvalid}
        className={classNames(
          'inline-flex px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-75',
          isEmailInvalid ? '' : 'hover:bg-indigo-700',
        )}
      >
        Submit
      </button>
    </form>
  )
}
