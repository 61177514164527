import { Destination } from '@/api/destinations/types/Destination'
import { EnvironmentSearchRow } from '@/api/environments/types/EnvironmentSearchRow'

import { PagedResponse } from '@/types/PagedResponse'

import { ApiClient } from '../client'

import { DestinationCreateBody } from './types/DestinationCreateBody'
import { DestinationSearchFilter } from './types/DestinationSearchFilter'
import { DestinationSearchRow } from './types/DestinationSearchRow'

const baseURL = 'v1.0/destinations'
const environmentsURL = 'v1.0/environments'

export const addDestination = async (body: DestinationCreateBody) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request({
      method: 'post',
      url: `${baseURL}`,
      data: body,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to add Destination. Error: ${error}`)
  }
}

export const getDestination = async (uuid?: string): Promise<Destination> => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<Destination>({
      method: 'get',
      url: `${baseURL}/${uuid}`,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to get Destination with UUID: ${uuid}. Error: ${error}`)
  }
}

export const getAllEnvironments = async (): Promise<PagedResponse<EnvironmentSearchRow>> => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<PagedResponse<EnvironmentSearchRow>>({
      method: 'get',
      url: `${environmentsURL}/search`,
    })

    return data
  } catch (error) {
    throw new Error(`Environments search failed. Error: ${error}`)
  }
}

export const updateDestination = async (destination?: Destination) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<boolean>({
      method: 'put',
      url: `${baseURL}/${destination?.uuid}`,
      data: destination,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to update Destination. Error: ${error}`)
  }
}

export const deleteDestination = async (uuid?: string) => {
  const client = new ApiClient()

  try {
    console.log('DELETE', `${baseURL}/${uuid}`)
    const { data } = await client.request<boolean>({
      method: 'delete',
      url: `${baseURL}/${uuid}`,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to delete Destination. Error: ${error}`)
  }
}

export const searchDestinations = async (filter?: DestinationSearchFilter) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<PagedResponse<DestinationSearchRow>>({
      method: 'get',
      url: `${baseURL}/search`,
      params: filter,
    })

    return data
  } catch (error) {
    throw new Error(`Destination search failed. Error: ${error}`)
  }
}
