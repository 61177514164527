import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'

import { getAllRoles } from '../../api/roles'
import { addUserRole, getUserRoles, removeUserRole, updateUser } from '../../api/users'

type UserResponse = Awaited<ReturnType<typeof getUserRoles>>
type UserParams = { uuid: string }
type AllRoles = Awaited<ReturnType<typeof getAllRoles>>

export const UsersEdit: React.FC = () => {
  const [user, setUser] = useState<UserResponse>()
  const [roles, setRoles] = useState<AllRoles>()
  const { uuid } = useParams<UserParams>()
  const [isEmailInvalid, setIsEmailInvalid] = useState(true)

  useEffect(() => {
    getUserRoles(uuid).then(setUser)
    getAllRoles().then(setRoles)
  }, [uuid])

  useEffect(() => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    setIsEmailInvalid(!regex.test(user?.email ?? ''))
  }, [setIsEmailInvalid, user?.email])

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      email: event.target.value,
    } as UserResponse)
  }

  const onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      providerType: event.target.value,
    } as UserResponse)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (isEmailInvalid) {
      return
    }

    updateUser(user)
  }

  const deleteRole = (roleUuid: string) => {
    removeUserRole(user?.uuid, roleUuid)
      .then(result => {
        if (result && user) {
          const index = user.roles.findIndex(r => r.uuid == roleUuid)
          if (index > -1) {
            const roles = user.roles
            roles.splice(index, 1)
            setUser({
              ...user,
              roles: roles,
            })
          }
        }
      })
      .catch(err => console.error(err))
  }

  const addRole = (roleUuid: string) => {
    addUserRole(user?.uuid, roleUuid)
      .then(result => {
        if (result && user) {
          const role = roles?.find(r => r.uuid == roleUuid)
          if (role) {
            setUser({
              ...user,
              roles: [...user.roles, role],
            })
          }
        }
      })
      .catch(err => console.error(err))
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="my-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            User Email
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="email"
              name="email"
              id="email"
              className={classNames(
                'block w-full p-2 rounded-md sm:text-sm',
                isEmailInvalid
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                  : 'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500',
              )}
              defaultValue={user?.email}
              aria-invalid={isEmailInvalid ? true : false}
              aria-describedby={isEmailInvalid ? 'email-error' : ''}
              formNoValidate={true}
              onChange={onChangeEmail}
            />
          </div>
          {isEmailInvalid && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Please enter a valid email address.
            </p>
          )}
        </div>
        <div className="relative mt-1 rounded-md shadow-sm">
          <label htmlFor="type" className="block text-sm font-medium text-gray-700">
            User Type
          </label>
          <input
            id="type"
            className="block w-full p-2 rounded-md sm:text-sm"
            defaultValue={user?.providerType}
            onChange={onChangeType}
          ></input>
        </div>
        <div className="relative mt-1 rounded-md shadow-sm">
          {roles?.map(role => {
            const hasRole = (user?.roles.findIndex(r => r.uuid == role.uuid) ?? -1) > -1
            return (
              <div key={role.uuid}>
                <p>{role.name}</p>
                <p>{role.description}</p>
                <button
                  className="bg-indigo-600 hover:text-indigo-900 text-white font-bold py-2 px-4 rounded mt-[15px] disabled:opacity-75"
                  onClick={() => addRole(role.uuid)}
                  disabled={hasRole}
                >
                  Add Role
                </button>
                <button
                  className="bg-indigo-600 hover:text-indigo-900 text-white font-bold py-2 px-4 rounded mt-[15px] disabled:opacity-75"
                  onClick={() => deleteRole(role.uuid)}
                  disabled={!hasRole}
                >
                  Delete Role
                </button>
              </div>
            )
          })}
        </div>
        <button
          type="submit"
          value="Submit"
          disabled={isEmailInvalid}
          className={classNames(
            'inline-flex px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm  focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-75',
            isEmailInvalid ? '' : 'hover:bg-indigo-700',
          )}
        >
          Submit
        </button>
      </form>
    </>
  )
}
