import React from 'react'

import { UserProvider } from '../context/user-context/UserProvider'

import { Layout } from './Layout'

export const App: React.FC = () => {
  return (
    <UserProvider>
      <Layout />
    </UserProvider>
  )
}
