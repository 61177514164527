import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import { JsonEditor } from '@/components/JsonEditor'

import { getOrganization, updateOrganization } from '@/api/organizations'
import { Organization } from '@/api/organizations/types/Organization'

import { Nullable } from '@/types/Nullable'

type OrganizationParams = { uuid: string }

export const OrganizationEditPage: React.FC = () => {
  const [organization, setOrganization] = useState<Organization | undefined>()
  const [jsonIsValid, setJsonIsValid] = useState(false)
  const [showError, setShowError] = useState(false)
  const [rawJsonText, setRawJsonText] = useState<string>('')

  const { uuid } = useParams<OrganizationParams>()

  useEffect(() => {
    getOrganization(uuid).then(setOrganization)
  }, [uuid])

  const navigate = useNavigate()
  const routeChange = () => {
    const path = `/organizations`
    navigate(path)
  }

  useEffect(() => {
    setShowError(false)
  }, [rawJsonText])

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganization({
      ...organization,
      name: event.target.value,
    } as Organization)
  }

  const onChangeSite = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganization({
      ...organization,
      site: event.target.value,
    } as Organization)
  }

  const setValue = (obj: Nullable<object>) => {
    setOrganization({
      ...organization,
      config: obj,
    } as Organization)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!jsonIsValid) {
      return
    }

    updateOrganization(organization)
      .then(routeChange)
      .catch(() => {
        setShowError(true)
      })
  }

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/organizations')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <form onSubmit={handleSubmit}>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Organization Name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="name"
                    name="name"
                    id="name"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={organization?.name}
                    onChange={onChangeName}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Organization Site
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="site"
                    name="site"
                    id="site"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={organization?.site}
                    onChange={onChangeSite}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Organization Config
                </label>
                {organization?.config && (
                  <JsonEditor
                    value={organization?.config}
                    setValue={setValue}
                    setIsValid={setJsonIsValid}
                    setRawValue={setRawJsonText}
                  />
                )}
              </div>
              <button
                type="submit"
                value="Submit"
                className={classNames(
                  'px-3 py-2 mx-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm',
                  !jsonIsValid ? 'bg-gray-600' : 'bg-indigo-600 hover:bg-indigo-500 focus:bg-indigo-500',
                )}
              >
                Submit
              </button>
              <button
                type="submit"
                value="Cancel"
                onClick={handleCancel}
                className={classNames(
                  'inline-flex px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500',
                )}
              >
                Cancel
              </button>
              {showError ? <p>There was a problem adding the organization!</p> : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
