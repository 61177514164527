import React from 'react'

import { UserContextState } from './UserContextState'
import { UserInfo } from './UserInfo'

export const anonymousUser = {
  name: '',
  email: '',
  initials: '',
  roles: [],
} as Readonly<UserInfo>

export const DefaultUserState = {
  isLoggedIn: false,
  user: anonymousUser,
}

export const UserContext = React.createContext<UserContextState>(DefaultUserState)
