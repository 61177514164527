import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

//import { useParams } from 'react-router-dom'
import classNames from 'classnames'

// import { getAllOrganizations } from '../../api/organizations'
import { getTenant } from '@/api/tenants'

type TenantResponse = Awaited<ReturnType<typeof getTenant>>
type TenantParams = { uuid: string }
// type TenantResponse = Awaited<ReturnType<typeof getTenantOrganizations>>
// type AllOrganizations = Awaited<ReturnType<typeof getAllOrganizations>>

export const TenantsViewPage: React.FC = () => {
  const navigate = useNavigate()
  const [tenant, setTenant] = useState<TenantResponse>()
  const { uuid } = useParams<TenantParams>()

  useEffect(() => {
    getTenant(uuid).then(setTenant)
  }, [uuid])

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/tenants')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="relative mt-1 rounded-md shadow-sm">{tenant?.name}</div>
            </div>
            {/*<div className="relative mt-1 rounded-md shadow-sm">
              {roles?.map(role => {
                const hasRole = (tenant?.roles.findIndex(r => r.uuid == role.uuid) ?? -1) > -1
                return (
                  <div key={role.uuid}>
                    <p>{role.name}</p>
                    <p>{role.description}</p>
                    <button
                      className="bg-indigo-600 hover:text-indigo-900 text-white font-bold py-2 px-4 rounded mt-[15px] disabled:opacity-75"
                      onClick={() => addRole(role.uuid)}
                      disabled={hasRole}
                    >
                      Add Role
                    </button>
                    <button
                      className="bg-indigo-600 hover:text-indigo-900 text-white font-bold py-2 px-4 rounded mt-[15px] disabled:opacity-75"
                      onClick={() => deleteRole(role.uuid)}
                      disabled={!hasRole}
                    >
                      Delete Role
                    </button>
                  </div>
                )
              })}
            </div>*/}
            <form>
              <button
                onClick={handleCancel}
                className={classNames(
                  'px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500',
                )}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
