import { ValidationErrors } from './ValidationErrors'

const PROBLEM_DETAIL_TYPE = 'https://tools.ietf.org/html/rfc7231#section-6.5.1'

export interface ProblemDetails {
  errors: ValidationErrors
  status: number
  title: string
  traceId?: string
  type?: typeof PROBLEM_DETAIL_TYPE
}

export const isProblemDetails = (value: unknown): value is ProblemDetails => {
  if (value && typeof value === 'object') {
    const problem = value as ProblemDetails
    return problem.type === PROBLEM_DETAIL_TYPE
  }
  return false
}
