import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'

import { searchUsers } from '../../api/users'
import { UserSearchRow } from '../../api/users/types/UserSearchRow'

type UserSearchResponse = Awaited<ReturnType<typeof searchUsers>>

const columnHelper = createColumnHelper<UserSearchRow>()

const columns = [
  columnHelper.accessor('email', {
    header: 'Email',
  }),
  columnHelper.accessor('providerType', {
    header: 'Provider Type',
  }),
]

export const UsersPage: React.FC = () => {
  const [users, setUsers] = useState<UserSearchResponse>()

  useEffect(() => {
    searchUsers().then(setUsers)
  }, [])

  const data = (users && users?.results) ?? []

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, i) => (
                <th
                  key={header.id}
                  scope="col"
                  className={classNames(
                    'py-3.5 text-left text-sm font-semibold text-gray-900',
                    i === 0 ? 'pl-4 pr-3 sm:pl-6' : 'px-3',
                  )}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          ))}
        </thead>
        <tbody className="bg-white">
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} className={row.index % 2 === 0 ? undefined : 'bg-gray-50'}>
              {row.getVisibleCells().map((cell, i) => (
                <td
                  key={cell.id}
                  className={classNames(
                    'whitespace-nowrap  py-4 text-sm text-gray-500',
                    i === 0 ? 'pl-4 sm:pl-6 font-medium' : 'px-3',
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                <a href={`/users/${row.original.uuid}`} className="text-indigo-600 hover:text-indigo-900">
                  Edit
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Link to="/users/add">Add User</Link>
    </>
  )
}
