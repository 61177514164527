import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import { getTenant, updateTenant } from '@/api/tenants'

type TenantResponse = Awaited<ReturnType<typeof getTenant>>
type TenantParams = { uuid: string }

export const TenantsEditPage: React.FC = () => {
  const navigate = useNavigate()
  const [tenant, setTenant] = useState<TenantResponse>()
  const { uuid } = useParams<TenantParams>()

  useEffect(() => {
    getTenant(uuid).then(setTenant)
  }, [uuid])

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTenant({
      ...tenant,
      name: event.target.value,
    } as TenantResponse)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    updateTenant(tenant).then(() => navigate('/tenants'))
  }

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/tenants')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <form onSubmit={handleSubmit}>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Tenant Name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="name"
                    name="name"
                    id="name"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={tenant?.name}
                    onChange={onChangeName}
                    formNoValidate={true}
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  value="Submit"
                  className={classNames(
                    'px-3 py-2 mx-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm  hover:bg-indigo-500 focus:bg-indigo-500',
                  )}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  value="Cancel"
                  onClick={handleCancel}
                  className={classNames(
                    'px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500',
                  )}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
