import { Dispatch, useEffect, useState } from 'react'

import { json } from '@codemirror/lang-json'
import { vscodeDark } from '@uiw/codemirror-theme-vscode'
import CodeMirror from '@uiw/react-codemirror'

import { Nullable } from '@/types/Nullable'

export interface JsonEditorProps {
  value: Nullable<object>
  setValue: (value: Nullable<object>) => void
  setIsValid?: Dispatch<React.SetStateAction<boolean>>
  setRawValue?: Dispatch<React.SetStateAction<string>>
}

export const JsonEditor = (props: JsonEditorProps) => {
  const { value, setValue, setIsValid, setRawValue } = props
  const [jsonObject, setJsonObject] = useState(value ? JSON.stringify(value) : '')

  useEffect(() => {
    setRawValue?.(jsonObject)
    if (jsonObject === undefined || jsonObject === null || jsonObject === '') {
      setIsValid?.(true)
      setValue?.(null)
      return
    }

    try {
      const parsed = JSON.parse(jsonObject)
      setValue?.(parsed)
      setIsValid?.(true)
    } catch {
      setIsValid?.(false)
    }
  }, [jsonObject, setIsValid, setRawValue])

  return (
    <CodeMirror
      value={jsonObject}
      extensions={[json()]}
      onChange={setJsonObject}
      theme={vscodeDark}
      minHeight={'200px'}
      indentWithTab={true}
      placeholder={'Enter any additional configuration here in JSON format.'}
      basicSetup={{
        lineNumbers: false,
        highlightActiveLineGutter: true,
        foldGutter: true,
        dropCursor: true,
        allowMultipleSelections: true,
      }}
    />
  )
}
