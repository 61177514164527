import { DestinationSearchRow } from '@/api/destinations/types/DestinationSearchRow'
import { Secret } from '@/api/secrets/types/Secret'

import { PagedResponse } from '@/types/PagedResponse'

import { ApiClient } from '../client'

import { SecretCreateBody } from './types/SecretCreateBody'
import { SecretSearchFilter } from './types/SecretSearchFilter'
import { SecretSearchRow } from './types/SecretSearchRow'

const baseURL = 'v1.0/secrets'
const destinationsURL = 'v1.0/destinations'

export const addSecret = async (body: SecretCreateBody) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request({
      method: 'post',
      url: `${baseURL}`,
      data: body,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to add Secret. Error: ${error}`)
  }
}

export const getSecret = async (uuid?: string): Promise<Secret> => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<Secret>({
      method: 'get',
      url: `${baseURL}/${uuid}`,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to get Secret with UUID: ${uuid}. Error: ${error}`)
  }
}

export const getAllDestinations = async (): Promise<PagedResponse<DestinationSearchRow>> => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<PagedResponse<DestinationSearchRow>>({
      method: 'get',
      url: `${destinationsURL}/search?secretnotset=true`,
    })

    return data
  } catch (error) {
    throw new Error(`Destination search failed. Error: ${error}`)
  }
}

export const updateSecret = async (secret?: Secret) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<boolean>({
      method: 'put',
      url: `${baseURL}/${secret?.uuid}`,
      data: secret,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to update Secret. Error: ${error}`)
  }
}

export const deleteSecret = async (uuid?: string) => {
  const client = new ApiClient()

  try {
    console.log('DELETE', `${baseURL}/${uuid}`)
    const { data } = await client.request<boolean>({
      method: 'delete',
      url: `${baseURL}/${uuid}`,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to delete Secret. Error: ${error}`)
  }
}

export const searchSecrets = async (filter?: SecretSearchFilter) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<PagedResponse<SecretSearchRow>>({
      method: 'get',
      url: `${baseURL}/search`,
      params: filter,
    })

    return data
  } catch (error) {
    throw new Error(`Secret search failed. Error: ${error}`)
  }
}
