import React, { useCallback, useEffect, useState } from 'react'

import { useInterval } from 'usehooks-ts'

import { useLoginManager } from '@/context/user-context/UserProvider'

import { TokenService } from '@/api/token/TokenService'
import { ConfigService } from '@/services/config/ConfigService'
import { getErrorMessage } from '@/utils/Errors'

import { CredentialResponse, Google } from './gsi-client'

export const GoogleLoginManager: React.FC = () => {
  const [google, setGoogle] = useState<Google>()
  const [googleIsLoading, setGoogleIsLoading] = useState(true)

  const loginManager = useLoginManager()
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const { clientID } = ConfigService.googleAuth()

  useInterval(
    () => {
      if (typeof window !== 'undefined' && window.google) {
        setGoogle(window.google)
        setGoogleIsLoading(false)
      }
    },
    googleIsLoading ? 100 : null,
  )

  const logMeIn = useCallback(
    (res: CredentialResponse) => {
      console.log(res)

      const input = {
        tokenID: res.credential || '',
      }

      TokenService.authGoogle(input)
        .then(result => {
          loginManager.logIn(result)
        })
        .catch(e => {
          loginManager.logOut()
          setErrorMessage(getErrorMessage(e, 'Login Failed'))
        })
    },

    [loginManager],
  )

  useEffect(() => {
    if (google) {
      const googleAccountApi = google.accounts.id

      googleAccountApi.initialize({
        client_id: clientID,
        ux_mode: 'popup',
        callback: logMeIn,
      })

      googleAccountApi.prompt()
    }
  }, [google, clientID, logMeIn])

  return <div>{!!errorMessage && <div className="p-3 text-white bg-red-900">{errorMessage}</div>}</div>
}
