import { ApiClient } from '../client'

import { Role } from './types/Role'

const baseURL = 'v1.0/roles'

export const getAllRoles = async () => {
  const client = new ApiClient()

  const { data } = await client.request<Array<Role>>({
    method: 'get',
    url: `${baseURL}/all`,
  })

  return data
}
