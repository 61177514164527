import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { App } from './layout/App'
import reportWebVitals from './reportWebVitals'

import './styles/tailwind-generated.css'

const container = document.getElementById('root') as Element

const root = createRoot(container)

console.log('running')

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
)

reportWebVitals()
