import { isProblemDetails } from '@/types/ProblemDetails'

export const getErrorMessage = (e: unknown, defaultErrorMessage: string) => {
  if (hasMessage(e)) {
    return e.message
  }

  if (isProblemDetails(e)) {
    return Object.entries(e.errors)
      .map((k, v) => `${k}=${v}`)
      .join(', ')
  }

  return defaultErrorMessage
}

export const hasMessage = (value: unknown): value is { message: string } => {
  const e = value as { message: string }
  return !!e?.message && typeof e.message === 'string' ? true : false
}
