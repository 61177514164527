import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { getSecret } from '@/api/secrets'
import { Secret } from '@/api/secrets/types/Secret'

type SecretParams = { uuid: string }

export const SecretViewPage: React.FC = () => {
  const [secret, setSecret] = useState<Secret | undefined>()
  const { uuid } = useParams<SecretParams>()

  useEffect(() => {
    getSecret(uuid).then(setSecret)
  }, [uuid])

  const navigate = useNavigate()
  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/secrets')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="my-4">
              <div className="relative mt-1 rounded-md shadow-sm">{secret?.name}</div>
            </div>
            <div className="my-4">
              <div className="relative mt-1">Type: {secret?.type}</div>
            </div>
            <div className="my-4">
              <div className="relative mt-1">Secret: {secret?.secret}</div>
            </div>
            <div className="my-4">
              <div className="relative mt-1">
                Destination:{' '}
                <Link
                  to={`/destinations/view/${secret?.destinationUUID}`}
                  className={'text-indigo-600 hover:text-indigo-900'}
                >
                  {secret?.destinationName}
                </Link>
              </div>
            </div>
            <form>
              <button
                onClick={handleCancel}
                className={
                  'inline-flex px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500'
                }
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
