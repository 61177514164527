import { PagedResponse } from '@/types/PagedResponse'

import { ApiClient } from '../client'

import { Tenant } from './types/Tenant'
import { TenantAdd } from './types/TenantAdd'
import { TenantSearchFilter } from './types/TenantSearchFilter'
import { TenantSearchRow } from './types/TenantSearchRow'

const baseURL = 'v1.0/tenants'

export const addTenant = async (name: string) => {
  const client = new ApiClient()

  const body: TenantAdd = {
    name,
  }

  const { data } = await client.request<Tenant>({
    method: 'post',
    url: `${baseURL}`,
    data: body,
  })

  return data
}

export const getTenant = async (uuid?: string) => {
  const client = new ApiClient()

  const { data } = await client.request<Tenant>({
    method: 'get',
    url: `${baseURL}/${uuid}`,
  })

  return data
}

export const updateTenant = async (tenant?: Tenant) => {
  const client = new ApiClient()

  const { data } = await client.request<boolean>({
    method: 'put',
    url: `${baseURL}/${tenant?.uuid}`,
    data: tenant,
  })

  return data
}

export const deleteTenant = async (uuid?: string) => {
  const client = new ApiClient()

  const { data } = await client.request<boolean>({
    method: 'delete',
    url: `${baseURL}/${uuid}`,
  })

  return data
}

export const searchTenants = async (filter?: TenantSearchFilter) => {
  const client = new ApiClient()

  const { data } = await client.request<PagedResponse<TenantSearchRow>>({
    method: 'get',
    url: `${baseURL}/search`,
    params: filter,
  })

  return data
}
