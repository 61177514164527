import { Organization } from '@/api/organizations/types/Organization'

import { PagedResponse } from '@/types/PagedResponse'

import { ApiClient } from '../client'

import { OrganizationCreateBody } from './types/OrganizationCreateBody'
import { OrganizationSearchFilter } from './types/OrganizationSearchFilter'
import { OrganizationSearchRow } from './types/OrganizationSearchRow'

const baseURL = 'v1.0/organizations'

export const addOrganization = async (body: OrganizationCreateBody) => {
  const client = new ApiClient()

  const { data } = await client.request({
    method: 'post',
    url: `${baseURL}`,
    data: body,
  })

  return data
}

export const getOrganization = async (uuid?: string): Promise<Organization> => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<Organization>({
      method: 'get',
      url: `${baseURL}/${uuid}`,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to get Organization with UUID: ${uuid}. Error: ${error}`)
  }
}

export const updateOrganization = async (organization?: Organization) => {
  const client = new ApiClient()

  const { data } = await client.request<boolean>({
    method: 'put',
    url: `${baseURL}/${organization?.uuid}`,
    data: organization,
  })

  return data
}

export const deleteOrganization = async (uuid?: string) => {
  const client = new ApiClient()

  const { data } = await client.request<boolean>({
    method: 'delete',
    url: `${baseURL}/${uuid}`,
  })

  return data
}

export const searchOrganizations = async (filter?: OrganizationSearchFilter) => {
  const client = new ApiClient()

  const { data } = await client.request<PagedResponse<OrganizationSearchRow>>({
    method: 'get',
    url: `${baseURL}/search`,
    params: filter,
  })

  return data
}
