import { ValidationErrors } from './ValidationErrors'

export enum ResultStatus {
  Ok,
  Invalid,
  Failed,
  Cancelled,
}

export interface ServiceResult<T> {
  status: ResultStatus
  value: T
  message: string
  validationErrors: ValidationErrors
}

export class Result {
  public static Ok<T>(value: T, message = 'Ok'): ServiceResult<T> {
    return {
      status: ResultStatus.Ok,
      value,
      message,
      validationErrors: {},
    }
  }

  public static Invalid<T>(validationErrors: ValidationErrors, message = 'Invalid'): ServiceResult<T> {
    return {
      status: ResultStatus.Invalid,
      value: undefined as never,
      message,
      validationErrors,
    }
  }

  public static Failed<T>(message: string): ServiceResult<T> {
    return {
      status: ResultStatus.Failed,
      value: undefined as never,
      message,
      validationErrors: {},
    }
  }

  public static Cancelled<T>(message = 'Cancelled'): ServiceResult<T> {
    return {
      status: ResultStatus.Cancelled,
      value: undefined as never,
      message,
      validationErrors: {},
    }
  }
}
