import { addSeconds, getTime, isAfter } from 'date-fns/fp'
import flow from 'lodash/fp/flow'

import { AuthResult } from '../../api/token/types/AuthResult'
import { Maybe } from '../../types/Maybe'

const LOCAL_STORAGE_KEY = 'user'

export class UserStorage {
  public static saveUser(user: AuthResult) {
    const data = {
      ...user,
      expiresAt: flow(addSeconds(user.expiresIn * 0.95), getTime)(new Date()),
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
  }

  public static removeUser() {
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  }

  public static getUser(): Maybe<AuthResult> {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY)
    const user = value ? JSON.parse(value) : undefined

    if (user && user.expires_at) {
      if (isAfter(user.expires_at)(new Date())) {
        UserStorage.removeUser()
        return undefined
      }
    }

    return user
  }
}
