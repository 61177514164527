import { ApiConfig } from './types/ApiConfig'
import { GoogleAuthConfig } from './types/GoogleAuthConfig'

export class ConfigService {
  public static apiConfig(): ApiConfig {
    return {
      url: process.env.REACT_APP_API_ENDPOINT ?? '',
    }
  }

  public static googleAuth(): GoogleAuthConfig {
    return {
      clientID: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID ?? '',
    }
  }
}
