import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'

import { addTenant } from '@/api/tenants'

export const TenantsAddPage: React.FC = () => {
  const navigate = useNavigate()
  const [name, setName] = useState('')

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    addTenant(name).then(() => navigate('/tenants'))
  }

  const handleCancel = (event: React.FormEvent) => {
    event.preventDefault()
    navigate('/tenants')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <form onSubmit={handleSubmit}>
              <div className="my-4">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  New Tenant
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    type="name"
                    name="name"
                    id="name"
                    className={classNames('block w-full p-2 rounded-md sm:text-sm')}
                    defaultValue={name}
                    formNoValidate={true}
                    onChange={onChangeName}
                  />
                </div>
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  Please enter a name.
                </p>
              </div>
              <div>
                <button
                  type="submit"
                  value="Submit"
                  className="px-3 py-2 mx-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm  hover:bg-indigo-500 focus:bg-indigo-500"
                >
                  Submit
                </button>
                <button
                  onClick={handleCancel}
                  className="px-3 py-2 mx-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm  hover:bg-gray-500 focus:bg-gray-500"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
