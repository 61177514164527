import { Environment } from '@/api/environments/types/Environment'
import { OrganizationSearchRow } from '@/api/organizations/types/OrganizationSearchRow'

import { PagedResponse } from '@/types/PagedResponse'

import { ApiClient } from '../client'

import { EnvironmentCreateBody } from './types/EnvironmentCreateBody'
import { EnvironmentSearchFilter } from './types/EnvironmentSearchFilter'
import { EnvironmentSearchRow } from './types/EnvironmentSearchRow'

const baseURL = 'v1.0/environments'
const organizationsURL = 'v1.0/organizations'

export const addEnvironment = async (body: EnvironmentCreateBody) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request({
      method: 'post',
      url: `${baseURL}`,
      data: body,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to add Environment. Error: ${error}`)
  }
}

export const getEnvironment = async (uuid?: string): Promise<Environment> => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<Environment>({
      method: 'get',
      url: `${baseURL}/${uuid}`,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to get Environment with UUID: ${uuid}. Error: ${error}`)
  }
}

export const getAllOrganizations = async (): Promise<PagedResponse<OrganizationSearchRow>> => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<PagedResponse<OrganizationSearchRow>>({
      method: 'get',
      url: `${organizationsURL}/search`,
    })

    return data
  } catch (error) {
    throw new Error(`Organization search failed. Error: ${error}`)
  }
}

export const updateEnvironment = async (environment?: Environment) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<boolean>({
      method: 'put',
      url: `${baseURL}/${environment?.uuid}`,
      data: environment,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to update Environment. Error: ${error}`)
  }
}

export const deleteEnvironment = async (uuid?: string) => {
  const client = new ApiClient()

  try {
    console.log('DELETE', `${baseURL}/${uuid}`)
    const { data } = await client.request<boolean>({
      method: 'delete',
      url: `${baseURL}/${uuid}`,
    })

    return data
  } catch (error) {
    throw new Error(`Failed to delete Environment. Error: ${error}`)
  }
}

export const searchEnvironments = async (filter?: EnvironmentSearchFilter) => {
  const client = new ApiClient()

  try {
    const { data } = await client.request<PagedResponse<EnvironmentSearchRow>>({
      method: 'get',
      url: `${baseURL}/search`,
      params: filter,
    })

    return data
  } catch (error) {
    throw new Error(`Environment search failed. Error: ${error}`)
  }
}
