import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { ConfigService } from '@/services/config/ConfigService'
import { UserStorage } from '@/services/login/UserStorage'

import { isProblemDetails } from '@/types/ProblemDetails'
import { Result, ServiceResult } from '@/types/Result'

export class ApiClient {
  private readonly _client: AxiosInstance

  constructor() {
    this._client = ApiClient.createClient()
  }

  public static getErrorResult<T>(error: unknown): ServiceResult<T> {
    if (axios.isCancel(error)) {
      return Result.Cancelled()
    }

    if (axios.isAxiosError(error)) {
      const data = error?.response?.data

      if (isProblemDetails(data)) {
        return Result.Invalid(data.errors)
      }

      return Result.Failed(error.message)
    }

    return Result.Failed('Request failed')
  }

  private static createClient(): AxiosInstance {
    const { url } = ConfigService.apiConfig()

    const client = axios.create({
      baseURL: `${url}/api/`,
      headers: {
        accept: 'application/json',
      },
      validateStatus: statusCode => statusCode === 200 || statusCode === 404,
    })

    client.interceptors.request.use(rq => {
      const user = UserStorage.getUser()

      if (user?.token) {
        return Promise.resolve({
          ...rq,
          headers: {
            ...rq.headers,
            Authorization: `Bearer ${user.token}`,
          },
        })
      }

      return Promise.resolve(rq)
    })

    return client
  }

  public async request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      return await this._client.request(config)
    } catch (err) {
      if (axios.isAxiosError(err) && typeof err?.response?.data === 'string') {
        return Promise.reject(new Error(err.response.data))
      }
      throw err
    }
  }
}
