import { AuthResult } from '../../api/token/types/AuthResult'
import { UserAction } from '../../context/user-context/UserReducer'

import { UserStorage } from './UserStorage'

export class LoginManager {
  private actions: UserAction

  constructor(actions: UserAction) {
    this.actions = actions
  }

  public logIn(authResult: AuthResult): Promise<void> {
    UserStorage.saveUser(authResult)
    this.actions.login(authResult)
    return Promise.resolve()
  }

  public logOut(): Promise<void> {
    UserStorage.removeUser()
    this.actions.logout()
    return Promise.resolve()
  }
}
