import { useUser } from '@/context/user-context/UserProvider'

export const SignedInUser = () => {
  const user = useUser()

  return (
    <div className="flex-shrink-0 flex bg-gray-700 p-4">
      <div className="flex items-center">
        <div>
          <img className="inline-block h-9 w-9 rounded-full" src={user.profileImage} alt="" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-white">{user.name}</p>
          <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">{user.email}</p>
        </div>
      </div>
    </div>
  )
}
